import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import SEO from '../components/SEO';

const PlayerStyles = styled.div`
  h1 {
    text-align: center;
    margin-bottom: 1rem;
  }
  h3 {
    text-align: center;
    margin-bottom: 1rem;
  }
  .rows {
    display: flex;
    justify-content: center;
    gap: 20px;
    &.names p {
      font-size: 20px;
    }
  }
  .rows p {
    text-align: center;
    margin: 0 0 1rem;
  }
  .cardimage {
    margin: 10px auto;
    max-width: 1000px;
    &.verticle {
      max-width: 550px;
    }
  }
  .teamlist {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    a {
      display: block;
      text-align: center;
      text-decoration: none;
      max-width: 100px;
    }
    img.teamlogo {
      display: block;
      margin: 5px auto;
      width: 13px;
      image-rendering: pixelated;
    }
  }
  .linklist {
    list-style: none;
    margin: 2em auto;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
  }
`;
function calculateAge(birthday, debut = Date.now()) {
  //  or if person is dead or calculating debut age, use second para
  const ageDifMs = new Date(debut) - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs);
  // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export default function PlayerDetailPage({ data }) {
  const playerData = data.player;

  const pastteams = [];
  playerData.teamsplayed.forEach((team) => {
    if (team.id !== playerData.currentteam.id) {
      pastteams.push(
        <Link to={`/team/${team.slug.current}`} key={team.id}>
          <img
            src={team.image?.asset?.fixed?.src}
            className="teamlogo"
            alt={team.name}
          />
          {team.name}
        </Link>
      );
    }
  });

  const playerlink = [];
  // https://www.mlb.com/video/search?q=PlayerId+%3D%3D+%5B685503%5D+Order+By+Timestamp
  if (playerData.href_bbref) {
    playerlink.push(
      <a
        href={playerData.href_bbref}
        key={playerlink.length}
        target="_new"
        className="button"
      >
        Baseball-reference.com
      </a>
    );
  }
  if (playerData.href_mlb) {
    playerlink.push(
      <a
        href={playerData.href_mlb}
        key={playerlink.length}
        target="_new"
        className="button"
      >
        MLB.com
      </a>
    );
  }
  if (playerData.href_nikkan) {
    playerlink.push(
      <a
        href={playerData.href_nikkan}
        key={playerlink.length}
        target="_new"
        className="button"
      >
        Nikkan Sports（日刊）
      </a>
    );
  }
  if (playerData.href_npb) {
    playerlink.push(
      <a
        href={playerData.href_npb}
        key={playerlink.length}
        target="_new"
        className="button"
      >
        Nippon Professional Baseball（日本プロ野球）
      </a>
    );
  }
  playerlink.push(
    <a
      href={`https://news.google.com/search?q=${playerData.name}`}
      key={playerlink.length}
      target="_new"
      className="button"
    >
      Google News
    </a>
  );
  playerlink.push(
    <a
      href={`https://news.google.com/search?q=${playerData.namejp}&hl=ja&gl=JP&ceid=JP:ja`}
      key={playerlink.length}
      target="_new"
      className="button"
    >
      グーグル・ニュース
    </a>
  );
  playerlink.push(
    <a
      href={`https://en.wikipedia.org/wiki/${playerData.name}`}
      key={playerlink.length}
      target="_new"
      className="button"
    >
      Wikipedia
    </a>
  );
  playerlink.push(
    <a
      href={`https://ja.wikipedia.org/wiki/${playerData.namejp}`}
      key={playerlink.length}
      target="_new"
      className="button"
    >
      ウィキペディア
    </a>
  );
  playerlink.push(
    <a
      href={`https://www.baseball-reference.com/bullpen/${playerData.name}`}
      key={playerlink.length}
      target="_new"
      className="button"
    >
      Bullpen
    </a>
  );
  playerlink.push(
    <a
      href={`https://www.ebay.com/sch/i.html?_nkw=${playerData.name}&_sacat=212&LH_BIN=1`}
      key={playerlink.length}
      target="_new"
      className="button"
    >
      Ebay
    </a>
  );
  playerlink.push(
    <a
      href={`https://auctions.yahoo.co.jp/search/search?auccat=21000&ei=utf-8&p=${playerData.namejp}`}
      key={playerlink.length}
      target="_new"
      className="button"
    >
      ヤフオク
    </a>
  );

  const playerImg = [];
  if (playerData.image) {
    const vert =
      playerData.image.asset.fluid.aspectRatio < 1
        ? 'cardimage verticle'
        : 'cardimage';
    playerImg.push(
      <Img
        fluid={playerData.image.asset.fluid}
        className={vert}
        key={playerImg.length}
      />
    );
  }

  // console.log(playerData.teamsplayed);
  return (
    <>
      <SEO title={playerData.name} />
      <PlayerStyles>
        <h1>{playerData.name}</h1>
        <h3 style={{ color: playerData.currentteam.color }}>
          {playerData.currentteam.name}
          {playerData.currentminor ? '(Minor Affiliate)' : ' '}
          <strong className="noto">{playerData.currentteam.namejp}</strong>
        </h3>
        <div className="playerimage">{playerImg}</div>
        <div className="rows names">
          <p>
            <strong>{playerData.name}</strong>
          </p>
          <p>
            <strong>{playerData.namejp}</strong>
          </p>
          <p>{playerData.nameyomi}</p>
        </div>
        <div className="rows">
          <p>
            Bats: <strong>{playerData.bats}</strong>
          </p>
          <p>
            Throws: <strong>{playerData.throws}</strong>
          </p>
          <p>{playerData.position}</p>
        </div>
        <div className="rows">
          <p>
            Date of Birth: <strong>{playerData.dob}</strong>
          </p>
          <p>
            Age:{' '}
            <strong>
              {playerData.dod
                ? calculateAge(playerData.dob, playerData.dod)
                : calculateAge(playerData.dob)}
            </strong>
          </p>
          <p>
            MLB Debute:{' '}
            <strong>
              {playerData.debut} (
              {calculateAge(playerData.dob, playerData.debut)})
            </strong>
          </p>
        </div>

        <div className="teamlist">
          <Link to={`/team/${playerData.currentteam.slug.current}`}>
            <img
              src={playerData.currentteam.image?.asset?.fixed?.src}
              className="teamlogo"
              alt={playerData.currentteam.name}
            />
            {playerData.currentteam.name}
          </Link>
          {pastteams}
        </div>
        <div className="linklist">{playerlink}</div>
      </PlayerStyles>
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    player: sanityPlayer(slug: { current: { eq: $slug } }) {
      id
      slug {
        current
      }
      icon
      status
      name
      namejp
      nameyomi
      bats
      throws
      position
      size
      debut
      dob
      dod
      image {
        asset {
          fixed(width: 600, height: 200) {
            ...GatsbySanityImageFixed
          }
          fluid(maxWidth: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
      currentteam {
        color
        division
        id
        league
        mlb
        name
        namejp
        slug {
          current
        }
        image {
          asset {
            fixed {
              src
            }
          }
        }
      }
      currentminor
      teamsplayed {
        color
        division
        id
        league
        mlb
        name
        namejp
        slug {
          current
        }
        image {
          asset {
            fixed {
              src
            }
          }
        }
      }
      href_bbref
      href_mlb
      href_nikkan
      href_npb
    }
  }
`;
